@import "./mixins";

:root {
  // Global colors
  --color-black: #181d27;
  --color-black-30: rgba(24, 29, 39, 30%);
  --color-black-50: rgba(24, 29, 39, 50%);
  --color-inputs-light: #bebedd;
  --color-inputs-dark: #8f98a9;
  --color-inputs-disable: #d8d8d8;
  --color-light: #f3f3ff;
  --color-light2: #bfbfe1;
  --color-light-hover: #d8d8f9;
  --color-light-stroke: #cfcffd;
  --color-gray: #b6bac0;
  --color-gray-light: #e9e9fb;
  --color-green: #29bb5b;
  --color-red: #f82121;
  --color-red-50: rgba(248, 33, 33, 50%);
  --color-red2: #c53a3a;
  --color-red-40: rgba(248, 33, 33, 40%);
  --color-violet: #6154f5;
  --color-violet-60: rgba(97, 84, 245, 60%);
  --color-violet-40: rgba(97, 84, 245, 40%);
  --color-violet-hover: #3f30ef;
  --color-violet-disabled: #b6bac0;
  --color-white: #fff;
  --color-white-60: rgba(255, 255, 255, 60%);
  --color-white-80: rgba(255, 255, 255, 80%);
  --color-yellow: #fbaf3d;
  --color-jira: #005eef;

  // Test case colors
  --test-case-color-untested: #8394b7;
  --test-case-color-passed: #32b968;
  --test-case-color-blocked: #b0aec2;
  --test-case-color-retest: #fbaf3d;
  --test-case-color-failed: #d3342a;

  // Task status colors
  --task-status-color-todo: #8394b7;
  --task-status-color-in-progress: #3f30ef;
  --task-status-color-running: #0047ff;
  --task-status-color-completed: #32b968;
  --task-status-bg-color-running: rgba(0, 72, 255, 10%);

  // Test Case status colors
  --color-status-untested: var(--test-case-color-untested);
  --color-status-passed: var(--test-case-color-passed);
  --color-status-blocked: var(--test-case-color-blocked);
  --color-status-retest: var(--test-case-color-retest);
  --color-status-failed: var(--test-case-color-failed);

  // Main Status colors
  --color-status-created: #7c71f8;
  --color-status-assigned: #17abff;
  --color-status-tested: #20b653;
  --color-status-deleted: #eb0000;
  --color-status-marked: #f80;
  --color-status-added: #fb1fff;
  --color-status-edit: #0047ff;
  --color-status-removed: #e55231;

  // Background Main status colors (Переведенные из Hex в RGB)
  --bg-status-created: rgba(124, 113, 248, 10%);
  --bg-status-assigned: rgba(0, 150, 235, 10%);
  --bg-status-tested: rgba(32, 182, 83, 10%);
  --bg-status-deleted: rgba(235, 0, 0, 10%);
  --bg-status-marked: rgba(255, 136, 0, 10%);
  --bg-status-added: rgba(251, 31, 255, 10%);
  --bg-status-edit: rgba(0, 71, 255, 10%);
  --bg-status-removed: rgba(229, 82, 49, 10%);

  // Completed status colors
  --color-status-completed: var(--task-status-color-completed);
  --color-status-inprocess: var(--task-status-color-in-progress);
  --color-status-todo: var(--task-status-color-todo);
  --color-status-progress: var(--task-status-color-in-progress);
  --color-completed: var(--color-green);
  --color-status-draft: var(--color-gray-light);
  --color-status-approved: var(--color-green);
  --color-integration-success: #46da00;

  // Password strength colors
  --color-password-easy: #d3342a;
  --color-password-medium: #fbaf3d;
  --color-password-hard: #32b968;

  // Indents
  --padding-component: 25px;
  --blocks-gap: 20px;
  --negative-blocks-gap: calc(-1 * var(--blocks-gap));
  --main-padding: var(--blocks-gap);
  --main-margin: var(--blocks-gap);
  --page-height: calc(var(--vh) * 100 - var(--header-height));

  // Other
  --gradient-auth:
    linear-gradient(
      105.18deg,
      #d1d1f8 0%,
      #f2f2ff 53.96%,
      #cdcdfa 100%
    );
  --shadow-menu: 0 0 20px rgba(188, 188, 205, 50%);
  --shadow-auth: 0 0 20px rgba(116, 116, 133, 50%);
  --shadow-sidebar: 11px 3px 14px rgba(116, 116, 133, 10%);
  --transition-fast: 0.15s;
  --transition-medium: 0.3s;
  --border-radius-default: 16px;

  // Garpix components
  --gx-input-height-small: 28px;
  --gx-input-height-medium: 40px;
  --gx-input-height-large: 50px;
  --gx-input-border-color: var(--color-inputs-light);
  --gx-input-border-color-hover: var(--color-violet-hover);
  --gx-input-border-color-focus: var(--color-violet-hover);
  --gx-input-border-color-disabled: var(--color-inputs-disable);
  --gx-input-border-radius-medium: 16px;
  --gx-input-background-color-disabled: transparent;
  --gx-input-color-disabled: var(--color-inputs-dark);
  --gx-input-placeholder-color: var(--color-inputs-light);
  --gx-input-placeholder-color-disabled: var(--color-inputs-dark);
  --gx-input-label-color: var(--color-black);
  --gx-input-label-font-size-medium: 16px;
  --gx-font-size-medium: 16px;
  --gx-input-font-family: muller, sans-serif;
  --gx-font-serif: muller, sans-serif;
  --gx-font-sans: muller, sans-serif;
  --gx-focus-ring-box-shadow: none;
  --gx-spacing-xxx-small: 5px;
  --gx-z-index-tooltip: 2;

  // Garpix components scrollbar styles
  --gx-scrollbar-thumb-color: var(--color-violet-60);
  --gx-scrollbar-track-color: var(--color-gray-light);
  --gx-scrollbar-width: 8px;
  --gx-scrollbar-height: 8px;

  // Header/sidebar parameters
  --heager-background-color: var(--color-white);
  --sidebar-height: var(--page-height);
  --sidebar-width: 250px;
  --padding-sidebar: 20px;
  --sidebar-width-small: 60px;
  --sidebar-item-height: 42px;
  --sidebar-dashboard-width: 192px;

  // Suites parameters
  --suites-max-width: 540px;
  --suites-min-width: 450px;

  // z-index components
  --index-default: 1;
  --index-sidebar: 100;
  --index-header: 101;
  --index-modal: 1000;
  --index-drop-files: 10000;

  // Error parameters
  --error-background-color: var(--color-gray-light);

  // Header & text sizes
  --font-regular: muller, sans-serif;
  --text-size-80: 150px;
  --text-size-73: 70px;
  --text-size-70: 50px;
  --text-size-65: 40px;
  --text-size-60: 32px;
  --text-size-50: 30px;
  --text-size-40: 26px;
  --text-size-30: 20px;
  --text-size-20: 18px;
  --text-size-15: 16px;
  --text-size-10: 14px;
  --text-size-05: 12px;
  --text-size-04: 10px;
  --text-size-02: 7px;
  --head-size-h1: 24px;
  --head-size-h2: 18px;
  --head-size-h3: 16px;
  --head-size-h4: 14px;
  --head-size-h5: 12px;
  --font-weight-bold: 800;
  --font-weight-semibold: 500;
  --font-weight-normal: 400;
  --font-weight-thin: 300;

  @include laptop-md {
    --padding-component: 20px;
  }

  @include tablet-lg {
    --head-size-h1: 28px;
    --head-size-h2: 24px;
    --head-size-h3: 18px;
  }

  @include mobile {
    --head-size-h1: 26px;
    --head-size-h2: 22px;
  }

  @include mobile-md {
    --padding-component: 15px;
  }
}
