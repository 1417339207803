.tooltip {
  --max-width: 300px;
  --gx-tooltip-border-radius: 6px;
  --gx-tooltip-background-color: var(--color-white);
  --gx-tooltip-font-size: 14px;
  --gx-tooltip-color: var(--color-black);
  --gx-tooltip-padding: 10px;
  --gx-tooltip-line-height: 120%;

  &::part(base) {
    filter: drop-shadow(0 0 20px rgba(188, 188, 205, 70%));
    word-break: break-word;
  }
}

.custom_tooltip {
  position: absolute;
  z-index: 90;
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 4px;
  padding: 8px;
  max-width: 500px;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(-10px);
  filter: drop-shadow(0 0 20px rgba(188, 188, 205, 70%));

  &__container {
    position: relative;
    display: inline-block;
  }

  &--visible {
    opacity: 1;
    transform: translateY(0);
  }

  &--top {
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);

    &::before {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 8px 8px 0;
      border-color: var(--color-white) transparent transparent transparent;
      border-style: solid;
    }
  }

  &--bottom {
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-43%);

    &::before {
      content: "";
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 0 8px 10px;
      border-color: transparent transparent var(--color-white) transparent;
      border-style: solid;
    }
  }

  &--bottom-start {
    top: calc(100% + 15px);

    &::before {
      content: "";
      position: absolute;
      top: -8px;
      left: 8%;
      transform: translateX(-50%);
      border-width: 0 8px 10px;
      border-color: transparent transparent var(--color-white) transparent;
      border-style: solid;
    }
  }

  &--left {
    top: 50%;
    right: calc(100% + 8px);
    transform: translateY(-50%);

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: -8px;
      transform: translateY(-50%);
      border-width: 8px 0 8px 8px;
      border-color: var(--color-white) transparent transparent transparent;
      border-style: solid;
    }
  }

  &--right {
    top: 50%;
    left: calc(100% + 8px);
    transform: translateY(-50%);

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -8px;
      transform: translateY(-50%);
      border-width: 8px 8px 8px 0;
      border-color: var(--color-white) transparent transparent transparent;
      border-style: solid;
    }
  }
}
